import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack';

export function useSubmit<T, Error = unknown>() {
  const pending = ref(false);
  const data = ref<T>();
  const error = ref<Error>();
  const { $api } = useNuxtApp();

  const accessToken = useCookie(useRuntimeConfig().public.auth?.provider?.token?.cookieName, { watch: true });

  async function execute(
    request: NitroFetchRequest,
    opts?: NitroFetchOptions<NitroFetchRequest, 'patch' | 'post' | 'put' | 'delete'> | undefined
  ) {
    try {
      error.value = undefined;
      pending.value = true;

      const noAuth = getUrlParam(request, 'X-NO-AUTH');
      const response = await $api<T>(request, {
        ...opts,
        headers: {
          ...opts?.headers,
          ...(!noAuth &&
            !opts?.headers?.Authorization &&
            accessToken.value && { Authorization: `Bearer ${accessToken.value}` }),
        },
      });
      data.value = response as T;
    } catch (e) {
      error.value = e as Error;
    } finally {
      pending.value = false;
    }
  }

  return {
    pending,
    data,
    error,
    execute,
  };
}
